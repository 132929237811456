<template>
  <div class="archive">
    <video-finite-item
        :video="cam"
    ></video-finite-item>
    <button>Полный экран</button>
  </div>
</template>

<script>
import VideoFiniteItem from "../../../components/video/VideoFiniteItem";
export default {
  name: "ArchivePleerView",
  components: {VideoFiniteItem},
  data(){
    return {
      cam:{
        uri: "https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8",
        blurPreference: {"id":12,"w":53,"h":68,"x":373,"y":72,"cw":480,"ch":270,"isRound":true}
      }
    }
  },
  mounted() {
    Chatra('kill')
  }
}
</script>

<style scoped>

</style>
